import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer class="footer">
      <h6>This page Created by Shawn McPhee</h6>
    </footer>
  );
}

export default Footer;
